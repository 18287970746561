// SecretPage.js
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/config"; // adjust the import path if needed
import downloadStyle from "./downloadStyle.module.css"; // your style module

function SecretPage() {
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!value) {
      alert("Please enter a code.");
      return;
    }

    try {
      // Try to retrieve the code document from Firestore
      const codeDocRef = doc(db, "codes", value);
      const codeDocSnap = await getDoc(codeDocRef);

      if (!codeDocSnap.exists()) {
        alert("Invalid code.");
        return;
      }

      const codeData = codeDocSnap.data();

      if (codeData.used) {
        alert("This code has already been used.");
        return;
      }

      // Mark the code as used so it cannot be used again
      await updateDoc(codeDocRef, { used: true });

      // Navigate to the download page, passing the code in the URL
      navigate(`/SecretDownload/${value}`);
    } catch (error) {
      console.error("Error during login:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className={downloadStyle.container}>
      <div className={downloadStyle.formContainer}>
        <p>Enter your Code here:</p>
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={downloadStyle.inputField}
        />
        <button onClick={handleLogin} className={downloadStyle.downloadButton}>
          Login
        </button>
      </div>
    </div>
  );
}

export default SecretPage;
