import React from 'react';
import styles from './Service.module.css';

export default function Service() {
    return (
        <div>
            <div className={styles.paragraphContainer}>
                <div>
                    <h3 className={styles.subtitle}>Seller</h3>
                    <p className={styles.paragraph}>ToursTrust - Are you a travel agency searching for an efficient solution to manage your finances, sales, and Salesmen? Look no further! Introducing our groundbreaking app - ToursTrust - the central hub for financial transparency, sales monitoring, and seamless tour management.</p>
                </div>
                <div>
                    <h3 className={styles.subtitle}>Operator</h3>
                    <p className={styles.paragraph}>As a proud owner of Products, our app provides you with complete oversight of your business. Effectively manage your products by gaining visibility into daily, weekly, and monthly earnings. This invaluable data allows you to optimize your tours and elevate their success. Furthermore, you have the power to select which travel agencies are authorized to sell your products. Choose wisely, ensuring that only the finest agencies offer your premium tours</p>
                </div>
            </div>

            <div className={styles.footer}>
                Website in progress, stay tuned!
            </div>
        </div>
    );
}