import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../component/ProfileCard.css';
import globalstyle from '../component/PageLayout.module.css'
import memberapi from '../firebase/memberapi';

function Data() {
  const [profile, setProfile] = useState([]);
  const { id } = useParams(); 

  useEffect(() => {
    async function fetchProfiles() {
      try {
        const data = await memberapi.read(id);
        setProfile(data);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    }

    fetchProfiles();
  }, [id]);

  return (
    <div className={globalstyle.firstelement}>
        <div className="profile-container">
            <div className="profile">
                <div className="profile-picture">
                    <img src={profile.imageurl} alt="Profile Picture" />
                </div>
                <div className="profile-details">
                    <h1 className="title">{profile.name}</h1>
                    <h2 className="name">{profile.title}</h2>
                    <div className="contact">
                        <div className="contact-item">
                            <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                            <a href={`mailto:${profile.email}`} className="contact-link">{profile.email}</a>
                        </div>
                        <div className="contact-item">
                          <FontAwesomeIcon icon={faPhone} className="contact-icon" />
                          <a href={`tel:${profile.phonenumber}`} className="contact-link">{profile.phonenumber}</a>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  );
}

export default Data;
