import React, { useState, useEffect } from 'react';
import ProfileCard from '../component/ProfileCard';
import memberapi from '../firebase/memberapi';
import './Team.css'; // Importieren Sie das CSS-Datei

function Team() {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    async function fetchProfiles() {
      try {
        const data = await memberapi.readAll();
        setProfiles(data);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    }

    fetchProfiles();
  }, []); 

  return (
    <div className="team-container"> 
      {profiles.map((profileData) => (
        <div className="profile-card"> 
          <ProfileCard key={profileData.id} profileData={profileData} />
        </div>
      ))}
    </div>
  );
}

export default Team;
