import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db, storage } from "../firebase/config"; // adjust the import path if needed
import { ref, getDownloadURL } from "firebase/storage";
import downloadStyle from "./downloadStyle.module.css"; // your style module

function SecretDownload() {
  const { code } = useParams(); // Code passed via the URL
  const navigate = useNavigate();
  const [accessGranted, setAccessGranted] = useState(false);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        // Verify the code exists and is marked as used
        const codeDocRef = doc(db, "codes", code);
        const codeDocSnap = await getDoc(codeDocRef);
        if (!codeDocSnap.exists() || !codeDocSnap.data().used) {
          navigate("/"); // Redirect if the code is invalid or not marked as used
        } else {
          // Only set accessGranted to true if the code check passes
          setAccessGranted(true);
        }
      } catch (error) {
        console.error("Access check failed:", error);
        navigate("/Home");
      }
    };

    checkAccess();
  }, [code, navigate]);

  const handleDownload = async () => {
    // Block download if access hasn't been granted
    if (!accessGranted) {
      alert("Access denied. You cannot download the file.");
      return;
    }
    try {
      // Specify the path where you uploaded your EXE file in Firebase Storage.
      const exeRef = ref(storage, "files/tourstrust.exe");
      const url = await getDownloadURL(exeRef);
      window.location.href = url; // Redirect to the file URL to trigger the download
    } catch (error) {
      console.error("Error fetching the download URL:", error);
      alert("Failed to retrieve download. Please try again later.");
    }
  };

  return (
    <div className={downloadStyle.container}>
      <div className={downloadStyle.card}>
        <p className={downloadStyle.downloadText}>Download the Program</p>
        <button onClick={handleDownload} className={downloadStyle.downloadButton}>
          Download
        </button>
      </div>
    </div>
  );
}

export default SecretDownload;