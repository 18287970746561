import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Header.module.css';
import logoTT from '../images/logoToursTrust.png';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the current pathname is either '/secretpage' or starts with '/secretdownload'
  const hideNavButtons = location.pathname.toLowerCase().startsWith('/secretpage') || 
                         location.pathname.toLowerCase().startsWith('/secretdownload');

  const handleNavigation = (goto) => {
    navigate(goto);
  };

  return (
    <nav className={styles.nav}>
      <div className={styles.logo}>
        <img src={logoTT} alt="ToursTrust Logo" />
      </div>
      <h1 className={styles.title}>ToursTrust</h1>
      {/* Only show nav buttons if not on secret pages */}
      {!hideNavButtons && (
        <div className={styles.navItems}>
          <button
            className={styles.button}
            onClick={() => handleNavigation('/')}
          >
            Home
          </button>
          <button
            className={styles.button}
            onClick={() => handleNavigation('/download')}
          >
            Download
          </button>
          <button
            className={styles.button}
            onClick={() => handleNavigation('/team')}
          >
            Team
          </button>
        </div>
      )}
    </nav>
  );
}

export default Header;
