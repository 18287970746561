import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDTJFNbEkLdjiO-Ri970gMlRo92PvtmfiY",
    authDomain: "tourtrust-bcb03.firebaseapp.com",
    projectId: "tourtrust-bcb03",
    storageBucket: "tourtrust-bcb03.appspot.com",
    messagingSenderId: "238651602591",
    appId: "1:238651602591:web:c94cb6bf4b4f28dcbf94aa",
    measurementId: "G-Q554YBMELE"
};


export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);